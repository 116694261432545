import Vue from "vue";
import { VTextField, VSelect } from "vuetify/lib";

export default Vue.component("string-filter-component", {
  components: {
    "v-text-field": VTextField,
    "v-select": VSelect,
  },
  props: {
    field: String,
    filterType: String,
    value: [String, Number, Boolean, Date],
    operator: String,
  },
  data() {
    return {
      commitedValue: "",
      currentOperator: "eq",
      currentValue: "",
      operators: [
        // { Title: "Igual", Value: "eq" },
        { Title: "Contém", Value: "contains" },
      ],
    };
  },
  created() {
    if (this.operator) {
      this.currentOperator = this.operators.find(
        (s) => s.Value == this.operator
      );
    } else {
      this.currentOperator = this.operators[0].Value;
    }
    this.currentValue = this.value;
  },
  template: `<div class="grid-cell-filter">
                <v-text-field label="Pesquisa" v-model='currentValue' @change='changeText'></v-text-field>
                <button v-if="currentValue" @click="reset" class="k-button k-button-icontext grid-cell-filter__action"><span class="icon-close"></span></button>
            </div>`,
  methods: {
    changeOperator(ev) {
      if (this.commitedValue) {
        this.$emit("change", {
          operator: this.currentOperator,
          field: this.field,
          value: this.currentValue,
          syntheticEvent: ev,
        });
      }
    },
    changeText(ev) {
      if (this.commitedValue != this.currentValue) {
        this.commitedValue = this.currentValue;
        this.$emit("change", {
          operator: this.currentOperator,
          field: this.field,
          value: this.currentValue,
          syntheticEvent: ev,
        });
      }
    },
    reset(ev) {
      this.currentValue = "";
      this.$emit("change", {
        operator: "",
        field: "",
        value: "",
        syntheticEvent: ev,
      });
    },
  },
});
