import Vue from "vue";

export default Vue.component("delete-cell-component", {
  props: {
    field: String,
    dataItem: Object,
  },
  template: `<td class=""><button class="k-button k-button-icontext" @click="removeHandler">
              <span class="icon-close"></span>
            </button></td>`,
  methods: {
    removeHandler() {
      this.$emit("remove", this.dataItem);
    },
  },
});
