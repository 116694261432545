import Vue from "vue";

export default Vue.component("edit-cell-component", {
  props: {
    field: String,
    dataItem: Object,
  },
  template: `<td><button class="k-button k-button-icontext" @click="editHandler">
              <span class="icon-mode_edit"></span>
            </button></td>`,
  methods: {
    editHandler() {
      this.$emit("edit", this.dataItem);
    },
  },
});
